.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.text-field-label {
  font-family: Montserrat, sans-serif;
  color: #8d8d8d;
  font-weight: 400;
}

.logo {
  margin-bottom: 30px;
}

.form-block {
  width: 400px;
}

.primary-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #ff6a14;
  background-color: #ff6a14;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
}

.primary-button:hover {
  border-style: none;
  background-color: #da5000;
}

.text-field-2 {
  height: 50px;
  margin-bottom: 20px;
}

.text-field {
  height: 50px;
}

.description {
  margin-top: 20px;
  font-family: Montserrat, sans-serif;
  color: silver;
  text-align: center;
}

.grid {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.text-link {
  font-family: Montserrat, sans-serif;
  color: #ff6a14;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.body {
  background-color: #0e0e0e;
  background-image: url('../images/ring.svg');
  background-position: 50% 50%;
  background-size: cover;
}

@media screen and (max-width: 991px) {
  .logo {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .primary-button {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .form {
    width: auto;
  }

  .form-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .primary-button {
    margin-top: 20px;
  }

  .grid {
    width: 100%;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    justify-items: center;
  }

  .container {
    margin-top: 80px;
  }
}

#w-node-_39327cb4-68a7-3f87-45e1-59f6d457e3e5-3911144e {
  -ms-grid-column-align: center;
  justify-self: center;
}